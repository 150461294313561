import { render, staticRenderFns } from "./foryou.vue?vue&type=template&id=2dc6052f&"
import script from "./foryou.vue?vue&type=script&lang=js&"
export * from "./foryou.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WidgetFeaturedCommunity: require('/mnt/volume_berlin_01/frontends/mobile-community/components/WidgetFeaturedCommunity.vue').default,CommunityPost: require('/mnt/volume_berlin_01/frontends/mobile-community/components/CommunityPost.vue').default})
