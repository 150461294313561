
import setUrl from "~/mixins/setUrl.js";
export default {
  layout: "landing",
  ssr: false,
  fetchOnServer: false,
  components: {
    WidgetFeaturedCommunity: () =>
      import("@/components/WidgetFeaturedCommunity.vue"),
    WidgetTrendingCommunity: () =>
      import("@/components/WidgetTrendingCommunity.vue"),
  },
  mixins: [setUrl],
  data: () => ({
    modalFab: false,
    drawer: false,
    drawerTarget: "",
    slides: [],
    loading: {
      banner: false,
      comPopular: false,
      comNew: false,
      comList: false,
      comListing: false,
      more: false,
      trending: false,
    },
    comOwned: {
      lists: [],
      limit: 5,
      total: 0,
      offset: 1,
      empty: false,
    },
    dataWidgetFeatured: null,
    comNew: [],
    comListing: [],
    comPopular: [],
    trending: [],
    ads: {
      ads_1: {
        path: "/22728598014/Community-Below-Recommended",
        size: [320, 100],
        dom_id: "div-gpt-ad-1662522085424-0",
      },
      ads_2: {
        path: "/22728598014/Community-Below-My",
        size: [320, 250],
        dom_id: "div-gpt-ad-1662522295979-0",
      },
      ads_3: {
        path: "/22728598014/Community_Below_Widget",
        size: [320, 250],
        dom_id: "div-gpt-ad-1666265601352-0",
      },
    },
    sliderMenuData: [
      {
        name: "For You",
        onClick: {
          url: "/",
          sameDomain: true,
        },
      },
      {
        name: "Your Community",
        onClick: {
          url: "/communities",
          sameDomain: true,
        },
      },
      {
        name: "Listing",
        onClick: {
          url: "/listing",
          sameDomain: true,
        },
      },
    ],
    carouselSettings: {
      arrows: false,
      dots: false,
      infinite: false,
      variableWidth: true,
      // slidesToShow: 3,
    },
  }),
  beforeDestroy() {
    if (window.googletag) {
      window.googletag.destroySlots();
    }
  },
  mounted() {
    this.fetchData();
    setTimeout(() => {
      this.$defineSlots(this.ads.ads_1);
      this.$defineSlots(this.ads.ads_2);
      this.$defineSlots(this.ads.ads_3);
    }, 1000);
  },
  // watch: {
  //   "$store.state.auth.user.isLogin"() {
  //     if (this.$store.state.auth.user.isLogin) {
  //       this.fetchCommunityOwned();
  //     }
  //   },
  // },
  methods: {
    handleFabClick(item) {
      switch (item) {
        case "listing":
          this.drawer = true;
          this.drawerTarget = "listing";
          break;
        case "post":
          this.drawer = true;
          this.drawerTarget = "post";
          break;
        default:
          this.$router.push(this.localeLocation("/add"));
          break;
      }
    },
    closeFab() {
      this.modalFab = false;
    },
    clickFab() {
      this.modalFab = this.$refs["vue-tab"].active;
    },
    fetchData() {
      this.setSlides();
      this.fetchCommunityNew();
      this.fetchCommunityListing();
      this.fetchTrending();
      this.fetchWidgetFeaturedCommunity("239");
      if (this.$store.state.auth.user.isLogin) {
        // this.fetchCommunityOwned();
      }
    },
    async setSlides() {
      this.loading.banner = true;
      await this.$store
        .dispatch("public/getBannerData", 5)
        .then((res) => {
          this.loading.banner = false;
          if (res.body && res.body.length > 0) {
            res.body.map((obj) => {
              this.slides.push({
                created_at: obj.created_at,
                descnote_en: obj.descnote_en,
                descnote_id: obj.descnote_id,
                path_ori: obj.path_ori,
                id: obj.id,
                path_thumb: obj.path_thumb,
                rolepage: obj.rolepage,
                status: obj.status,
                wallnote_en: obj.wallnote_en,
                wallnote_id: obj.wallnote_id,
                cta: {
                  link: "",
                  text: "Buat Komunitas",
                },
              });
            });
          }
        })
        .catch(() => {
          this.loading.banner = false;
        });
    },
    loadMore() {
      this.comOwned.offset = this.comOwned.offset + 1;
      this.fetchCommunityOwned(true);
    },
    async fetchCommunityOwned(add = false) {
      this.loading.comList = true;
      this.loading.more = true;
      if (!add) {
        this.comOwned.lists = [];
      }
      const url = `?limit=${this.comOwned.limit}&p=${this.comOwned.offset}`;
      try {
        const res = await this.$getOwnedCommunities(
          this.comOwned.limit,
          this.comOwned.offset
        );
        if (res.body) {
          this.comOwned.lists.push(...res.body);
        }
        if (this.comOwned.lists.length === 0) {
          this.comOwned.empty = true;
        }
        this.comOwned.total = res.total_records;
      } catch (error) {
        throw new Error(error);
      } finally {
        this.loading.comList = false;
        this.loading.more = false;
      }
      // await this.$store
      //   .dispatch("public/getCommunityOwned", url)
      //   .then((res) => {
      //     if (res.body) {
      //       this.comOwned.lists.push(...res.body);
      //     }
      //     if (this.comOwned.lists.length === 0) {
      //       this.comOwned.empty = true;
      //     }
      //     this.comOwned.total = res.total_records;
      //     this.loading.comList = false;
      //     this.loading.more = false;
      //   })
      //   .catch((err) => {
      //     this.loading.more = false;
      //     this.loading.comList = false;
      //     throw new Error(err);
      //   });
    },
    async fetchWidgetFeaturedCommunity(id) {
      await this.$store
        .dispatch("public/getWidgetFeaturedCommunity", id)
        .then((res) => {
          // this.loading.comPopular = false
          this.dataWidgetFeatured = res;
        })
        .catch(() => {
          // this.loading.comPopular = false
        });
    },
    async fetchCommunityListing() {
      this.loading.comListing = true;
      try {
        const res = await this.$getListingContent();
        this.comListing = res;
      } catch (error) {
      } finally {
        this.loading.comListing = false;
      }
    },
    async fetchCommunityNew() {
      this.loading.comNew = true;
      try {
        const res = await this.$getCommunities();
        this.comNew = res;
      } catch (error) {
        throw error;
      } finally {
        this.loading.comNew = false;
      }
    },
    async fetchTrending() {
      this.loading.trending = true;
      try {
        const res = await this.$getPopularDiscussions("home");
        this.trending = res;
      } catch (error) {
      } finally {
        this.loading.trending = false;
      }
    },
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ["script"],
      script: [
        {
          async: true,
          src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
        },
      ],
    };
  },
};
