
import setUrl from "~/mixins/setUrl.js";
export default {
  layout: "landing",
  ssr: false,
  fetchOnServer: false,
  data: () => ({
    mixins: [setUrl],
    modalFab: false,
    drawer: false,
    drawerTarget: "",
    loading: {
      comList: false,
      comDiscover: false,
    },
    data: [],
    comOwned: {
      lists: [],
      limit: 5,
      total: 0,
      offset: 1,
      empty: false,
    },
    page: 1,
    limit: 10,
    search: "",
    keyword: "",
    noResult: false,
    searchResult: "",
  }),
  created() {
    this.search = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.searchResult = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
  },
  mounted() {
    this.search = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.searchResult = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
    if (this.$store.state.auth.user.isLogin) {
      this.fetchCommunityOwned();
    }
  },
  watch: {
    "$store.state.auth.user.isLogin"() {
      if (this.$store.state.auth.user.isLogin) {
        this.fetchCommunityOwned();
      }
    },
  },
  async fetch() {
    await this.fetchCommunity();
  },
  methods: {
    closeFab() {
      this.modalFab = false;
    },
    clickFab() {
      this.modalFab = this.$refs["vue-tab"].active;
    },
    handleFabClick(item) {
      switch (item) {
        case "listing":
          this.drawer = true;
          this.drawerTarget = "listing";
          break;
        case "post":
          this.drawer = true;
          this.drawerTarget = "post";
          break;
        default:
          this.$router.push(this.localeLocation("/add"));
          break;
      }
    },
    onSubmit() {
      this.data = [];
      // this.$router.push({ query: { keyword: this.search } });
      this.page = 1;
      this.searchResult = this.search;
      this.noResult = false;
      this.fetchCommunity();
    },
    loadMore() {
      this.page += 1;
      this.fetchCommunity();
    },
    async fetchCommunityOwned(add = false) {
      this.loading.comList = true;
      this.loading.more = true;
      if (!add) {
        this.comOwned.lists = [];
      }
      try {
        const res = await this.$getOwnedCommunities(
          this.comOwned.limit,
          this.comOwned.offset
        );
        if (res.body) {
          this.comOwned.lists.push(...res.body);
        }
        if (this.comOwned.lists.length === 0) {
          this.comOwned.empty = true;
        }
        this.comOwned.total = res.total_records;
      } catch (error) {
        throw new Error(err);
      } finally {
        this.loading.comList = false;
        this.loading.more = false;
      }
      // await this.$store
      //   .dispatch("public/getCommunityOwned", url)
      //   .then((res) => {
      //     if (res.body) {
      //       this.comOwned.lists.push(...res.body);
      //     }
      //     if (this.comOwned.lists.length === 0) {
      //       this.comOwned.empty = true;
      //     }
      //     this.comOwned.total = res.total_records;
      //     this.loading.comList = false;
      //     this.loading.more = false;
      //   })
      //   .catch((err) => {
      //     this.loading.more = false;
      //     this.loading.comList = false;
      //     throw new Error(err);
      //   });
    },
    async fetchCommunity() {
      this.loading.comDiscover = true;
      this.keyword = this.search;
      try {
        const res = await this.$getCommunities(
          this.limit,
          this.page,
          this.search,
          true
        );
        this.noResult = res && res.length === 0;
        console.log("RES", res);
        console.log("IS_EMPTY", this.noResult);
        console.log("LOADING", this.loading);
        this.data.push(...res);
      } catch (error) {
        throw error;
      } finally {
        this.loading.comDiscover = false;
      }
    },
  },
};
